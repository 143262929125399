import { render, staticRenderFns } from "./Resume.vue?vue&type=template&id=15606f54&scoped=true"
import script from "./Resume.vue?vue&type=script&lang=ts"
export * from "./Resume.vue?vue&type=script&lang=ts"
import style0 from "./Resume.vue?vue&type=style&index=0&id=15606f54&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15606f54",
  null
  
)

export default component.exports